import DOMPurify from 'dompurify';
import { Box,
    Container,
    Divider,
    Typography} from '@mui/material';
import docData from './site_data/DocumentData';
import {  useParams } from 'react-router-dom';


const DocumentRenderer = () => {
    const { contentKey } = useParams();
    const sanity = DOMPurify.sanitize;

    return (
        <Container>
            <Typography variant='h4' color='secondary.main'>
                {docData[contentKey].Title}
            </Typography>
            <Box sx={{bgcolor: 'secondary.main',
                      borderRadius: '10px'}}>
            {docData[contentKey].Headings.map((Heading, index) => (
                <Box>
                    <Divider variant='middle'>
                        <Typography variant='h6'
                                    color='subheading.main'>
                            {Heading}
                        </Typography>
                    </Divider>
                    <Typography variant='body1'
                                color='subheading.main'
                                sx={{paddingLeft: '2%',
                                    paddingRight: '2%',
                                    paddingBottom: '2%',
                                    boxSizing:'border-box'}}
                                dangerouslySetInnerHTML={{
                        __html: sanity(docData[contentKey].BodyContent[index])
                    }}/>
                </Box>
            ))}
            </Box>
        </Container>
    );
};

export default DocumentRenderer;
