import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Container,
    List,
    ListItem,
    Button
} from "@mui/material";
import {Link, useLocation} from 'react-router-dom';
import siteData from "./site_data/SiteData";


//TODO: Mapping to a component function
const TabRenderer = () => {
    const topic = useLocation().pathname.slice(1);

    const keyDataFormat = (keyArray) => {
        return(
            <List>
                {keyArray.map((data) =>
                    <ListItem>{data}</ListItem>)
                }
            </List>
        )
    };

    const accordionGen = siteData[topic].pagedata.map((accordionData)=>
        <Accordion disableGutters={true}>
            <AccordionSummary>
                {accordionData.title}
            </AccordionSummary>
            <AccordionDetails>
                {accordionData.content}
                {(typeof accordionData.keydata !== 'undefined' ?
                    keyDataFormat(accordionData.keydata) : "")}

                {(typeof accordionData.link !== 'undefined' ?
                    (<Button component={Link}
                             to={`/docs/${accordionData.link}`}
                             key={accordionData.title}>
                            More info here!
                    </Button>) : "")}
            </AccordionDetails>
        </Accordion>
    );

    return(
        <Container sx={{marginY:'10%'}}>
            {accordionGen}
        </Container>
    )
};


export default TabRenderer;
