import { Card,
    CardContent,
    CardMedia,
    Grid,
    Link,
    Typography } from "@mui/material";

import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';

import ugly_mug from './theming/mon_petite_visage.webp';


const PersonalInfo = () => (
      <Card>
          <CardMedia component='img' image={ugly_mug}/>
          <CardContent>
              <Typography variant='h4' color='heading.main'>
                  Joseph Owen Williamson
              </Typography>
              <Typography variant='h6' color='subheading.main'>Computer Science BSc (Hons)</Typography>
              <Typography variant='body1' align='center'>Disabled programmer from North Wales.<br />
                  Please feel free to contact me at any links below:
              </Typography>
              <Grid container align="center" paddingTop='3%'>
                  <Grid item xs={3}>
                      <Link href={'mailto:contact@owenwilliamson.co.uk'+
                         '?subject=Query%20From%20Website'}
                            color="subheading.main">
                          <EmailIcon/>
                      </Link>
                  </Grid>
                  <Grid item xs={3}>
                      <Link href='https://instagram.com/wheeliamson'
                            target='_blank'
                            rel="noreferrer"
                            color="subheading.main">
                          <InstagramIcon/>
                      </Link>
                  </Grid>
                  <Grid item xs={3}>
                      <Link href='http://linkedin.com/in/joseph-owen-williamson'
                            target='_blank'
                            rel="noreferrer"
                            color="subheading.main">
                          <LinkedInIcon/>
                      </Link>
                  </Grid>
                  <Grid item xs={3}>
                      <Link href='https://github.com/joowbo'
                            target='_blank'
                            rel="noreferrer"
                            color="subheading.main">
                          <GitHubIcon/>
                      </Link>
                  </Grid>
              </Grid>
          </CardContent>
      </Card>
);

export default PersonalInfo;