import WebHeader from './components/Header';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import PersonalInfo from './components/PersonalInfo';

import siteData from './components/site_data/SiteData';
import TabRenderer from "./components/TabRenderer";
import DocumentRenderer from './components/DocumentRenderer';
import { siteTheme } from './components/theming/siteTheme';


//TODO: Change header buttons to not be all caps
//TODO: Change tabname to be "Joseph Williamson"
//TODO: Create dark mode theme
//TODO: Write and add web V2, plytgthr and Vodafone content
const App = () => {
    //console.log(siteData);
    //FLOW: each KEY in siteData, new Route, data (array of OBJS) passed to TabRenderer
    const RouteGenerator = Object.keys(siteData).map((key, data)=>(
        <Route path={`${siteData[key].pageroute}`} key={key} element={<TabRenderer/>}/>
    ));

    return (
        <ThemeProvider theme={siteTheme}>
            <CssBaseline/>
            <BrowserRouter>
                <WebHeader/>
                <Routes>
                    <Route path={'/contact'} key={'contact'} element={<PersonalInfo/>}/>
                    {RouteGenerator}
                    <Route path={'/docs/:contentKey'} element={<DocumentRenderer/>}/>
                    <Route path={'*'} element={<Navigate to={'/contact'}/>}/>
                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    )
};

export default App;
