import { Link, useLocation } from "react-router-dom";
import siteData from "./site_data/SiteData";
import {AppBar,
        Button,
        Grid} from "@mui/material";


const WebHeader = () => {
    const activeTab = useLocation().pathname.slice(1);
    console.log(activeTab);

    return (
    <AppBar>
        <Grid container align="center">
        {Object.keys(siteData).map((key, value)=>(
            <Grid item
                  xs={3}>
                {console.log(key, " ", siteData[key].pageroute, " ")}
                <Button component={Link}
                        to={`${siteData[key].pageroute}`}
                        key={key}
                        color={key !== activeTab ? 'secondary': 'tertiary'}>
                    {key}
                </Button>
            </Grid>
        ))}
        </Grid>
    </AppBar>
    )
};

export default WebHeader;
