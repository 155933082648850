import { createTheme,
        experimental_sx as sx} from "@mui/material/styles";

export const siteTheme = createTheme({
    palette: {
        mode: 'light',
        background: {
            default: '#1e81b0',
        },
        primary: {
            main:'#4287f5',
        },
        secondary: {
            main: '#ffffff',
        },
        tertiary: {
            main: '#3c1152',
        },
        heading: {
            main: '#4a4841'
        },
        subheading: {
            main: '#6e6c69'
        }
    },
    typography: {
        h4: {
            fontSize: '2rem',
            '@media (max-width:600px)':{
                fontSize: '1.6rem',
            },
            paddingBottom:'1%',
            paddingTop: '1%',
            textAlign: 'center'

        },
        h6: {
            fontSize: '1.2rem',
            '@media (max-width:600px)':{
                fontSize: '1.1rem',
            },
            paddingBottom:'1%',
            textAlign: 'center'
        },
        subtitle1:{

        },
        body1:{
            '@media (max-width:600px)':{
                fontSize: '0.9rem',
            },
        }

    },
    components: {
        MuiAccordionSummary: {
            styleOverrides: {
                root: sx({
                    bgcolor:'primary.main',
                    color: 'secondary.main'
                })
            }
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: sx({
                    bgcolor:'secondary.main',
                    color: 'subheading.main'
                })
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: sx({
                    bgcolor:'primary.main',
                    position:'sticky'
                })
            }
        },
        MuiButton: {
            styleOverrides:{
                root: sx({

                })
            }
        },
        MuiCard: {
            styleOverrides: {
                root: sx({
                    background: 'transparent',
                    '@media (max-width:600px)':{
                        width: '96%'
                    },
                    width: 420,
                    marginX:'auto',
                    marginY:'5%',
                    border: 'none',
                    boxShadow: 'none'
                })
            },
            '@media (max-width:600px)':{
                width: '96%'
            },
        },
        MuiCardMedia: {
            styleOverrides: {
                root: sx({
                    height: '250px',
                    width: '250px',
                    display: 'block',
                    margin:'auto',
                    transform: 'translate(0,5%)'
                })
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: sx({
                    bgcolor:'secondary.main',
                    borderRadius: '25px'
                })
            }
        },
        MuiContainer: {
            styleOverrides: {
                root: sx({
                    //bgcolor:'secondary.main',
                    borderRadius: '10px',
                    marginY: '2%',
                    width: '100%'
                })
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: sx({
                    paddingY: '1%'
                })
            }
        }
    }
});